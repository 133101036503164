import React from 'react'
import {
	Box,
	Container,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
	makeStyles
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import PublisherSiteFooterLogo from './PublisherSiteFooterLogo'
import Logo2 from '../Logo2'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/FacebookOutlined'
import TwitterIcon from '@material-ui/icons/Twitter'

const useStyles = makeStyles((theme) => ({
	footerContainer: {
		position: 'absolute',
		bottom: 0,
		clear: 'both',
		width: '100%',
		paddingTop: theme.spacing(2.5),
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(1),
		},
	},
	logo: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
	}
}))

const links = [
	{
		title: 'Classes',
		href: '/yogarenew/videos',
	},
	{
		title: 'Courses',
		href: '/yogarenew/courses',
	},
	{
		title: 'Pricing',
		href: '/yogarenew/subscriptions',
	},
]

const PublisherSiteFooter = (props) => {
	const classes = useStyles()
	const { footerbackgroundcolor, socialIconColor } = props
	const year = new Date().getFullYear()

	return <Box className={classes.footerContainer} sx={{ pt: 4, mt: 4 }}>
		<Box
			sx={{
				backgroundColor: footerbackgroundcolor,
				pb: {
					md: 3,
					xs: 1,
				},
				pt: {
					md: 3,
					xs: 1,
				},
			}}
		>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<Grid
						item
						md={4}
						sm={4}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							order: {
								md: 1,
								xs: 4,
							},
						}}
						xs={12}
					>
						<Box
							sx={{
								alignItems: 'center',
								flexGrow: 1,
								mt: 2,
							}}
						>
							<Box className={classes.logo}>
								<RouterLink to="/yogarenew">
									<PublisherSiteFooterLogo
										sx={{
											display: {
												md: 'inline',
												xs: 'none',
											},
											height: 40,
											width: 60,
										}}
									/>
								</RouterLink>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									mt: {
										md: 2,
										sm: 1,
										xs: 0
									}
								}}>
								<Typography color="primary.contrastText" variant="caption">
									{/* NEED TO MAKE PUBLISHER NAME DYNAMIC */}© {year} YogaRenew. All rights
									reserved.
								</Typography>
								<Typography color="primary.contrastText" variant="caption">
									{/* <b>Terms</b>, <b>Privacy</b>, <b>Copyright</b> & <b>Cookies</b>. */}
									<Link component={RouterLink} to="/yogarenew/termsofuse" target="_blank" color="white" underline="hover" variant='subtitle2'>Terms</Link>, <Link component={RouterLink} to="/yogarenew/privacy-policy" target="_blank" color="white" underline="hover" variant='subtitle2'>Privacy</Link>, <b>Copyright</b> & <b>Cookies</b>.
								</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid
						item
						md={2}
						sm={2}
						sx={{
							display: {
								md: 'flex',
								// xs: 'inline-flex'
							},
							flexDirection: {
								md: 'column',
								xs: 'row'
							},
							order: {
								md: 1,
								xs: 1,
							},
							ml: {
								md: 0,
								sm: 0,
								xs: -1.75,
							},
							mb: {
								md: 0,
								sm: 0,
								xs: -1,
							}
						}}
						xs={12}
					>
						<List>
							{links.map((link) => (
								<ListItem
									key={link.title}
									sx={{
										pb: {
											md: 0.1,
											xs: 0,  // less padding for mobile
										},
										pt: {
											md: 0.1,
											xs: 0,  // less padding for mobile
										},
										mb: -0.5,
									}}
								>
									<ListItemText
										primary={
											<Link href={link.href} color="primary.contrastText" variant="subtitle2">
												{link.title}
											</Link>
										}
									/>
								</ListItem>
							))}
						</List>
					</Grid>
					<Grid
						item
						md={4}
						sm={4}
						sx={{
							display: 'flex',
							flexDirection: {
								md: 'column',
								xs: 'row'
							},
							order: {
								md: 1,
								xs: 1,
							},
						}}
						xs={12}
					>
						{/* NEED TO MAKE PUBLISHER NAME DYNAMIC */}
						<Typography
							color="primary.contrastText"
							sx={{
								mt: {
									md: 2,
									xs: 0,
								},
								fontWeight: '800'
							}
							}
							variant="subtitle2"
						>
							Contact Us:
						</Typography>
						<Typography color="primary.contrastText"
							sx={{
								mt: {
									md: 0.5,
									xs: 0,
								},
								ml: {
									md: 0,
									xs: .5,
								},
							}} variant="subtitle2">
							contact@yogarenewteachertraining.com
						</Typography>
					</Grid>

					<Grid
						item
						md={2}
						sm={2}
						xs={12}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							order: {
								md: 1,
								xs: 1,
							},
							mb: {
								md: 4,
								sm: 2,
								xs: -5,
							},
							mt: {
								md: 2,
								sm: 1,
								xs: -3,
							},
							ml: {
								md: 0,
								sm: 0,
								xs: -1.5,
							},
						}}
					>
						<IconButton href="https://www.facebook.com/YogaRenewTeacherTraining/" target="_blank">
							<FacebookIcon
								fontSize="medium"
								sx={{
									fontSize: {
										md: '32px',
										xs: '24px'
									},
									color: socialIconColor,
								}}
							/>
						</IconButton>
						<IconButton
							href="https://www.instagram.com/yogarenewteachertraining/"
							target="_blank"
						>
							<InstagramIcon
								fontSize="medium"
								sx={{
									fontSize: {
										md: '32px',
										xs: '24px'
									},
									color: socialIconColor,
								}}
							/>
						</IconButton>
						<IconButton href="https://twitter.com/YogaRenewYTT" target="_blank">
							<TwitterIcon
								fontSize="medium"
								sx={{
									fontSize: {
										md: '32px',
										xs: '24px'
									},
									color: socialIconColor,
								}}
							/>
						</IconButton>
					</Grid>
				</Grid>
			</Container>
		</Box>
		<Box
			sx={{
				backgroundColor: '#000000',
				pb: 1,
				pt: {
					md: 1,
					xs: 0,
				},
			}}
		>
			<Grid container sx={{ ml: 'auto', pr: 12, justifyContent: 'right' }}>
				<Grid item sx={{ mt: 3 }}>
					<Typography color="primary.contrastText" variant="body2">
						Created with
					</Typography>
				</Grid>
				<Grid item sx={{
					mt: {
						xs: 1,
						md: 0,
					},
					mb: {
						xs: -1,
						md: 0,
					}
				}}>
					<Logo2 sx={{
						width: {
							md: '40px',
							xs: '26px'
						},
					}} />
				</Grid>
			</Grid>
		</Box>
	</Box>
}

export default PublisherSiteFooter

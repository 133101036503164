import { useState, useEffect } from 'react'
import { fetchWorkshops } from "../../../actions/workshopActions";
import { Container, Typography, Box, Button, Dialog } from '@material-ui/core';
import WorkshopForm from './WorkshopForm';
import { WorkshopTable } from './WorkshopTable';

const today = new Date();
today.setSeconds(0, 0);

const WorkshopView = () => {
    const [scheduledWorkshops, setScheduledWorkshops] = useState([]);
    const [pastWorkshops, setPastWorkshops] = useState([]);
    const [showAddWorkshopDialog, setShowAddWorkshopDialog] = useState(false);
    const [scheduledWorkshopsLoading, setScheduledWorkshopsLoading] = useState(true);
    const [pastWorkshopsLoading, setPastWorkshopsLoading] = useState(true);

    const getPastWorkshops = async (skip = 0) => {
        if (!pastWorkshopsLoading) setPastWorkshopsLoading(true);

        const response = await fetchWorkshops({ filters: { date_lt: today.toISOString(), _start: skip } })
        if (response && response.length > 0) {
            const newList = [...pastWorkshops, ...response];
            setPastWorkshops(newList)

            // Recursively fetch until all past workshops are fetched
            if (response.length === 100) {
                await getPastWorkshops(newList.length)
            } else {
                setPastWorkshopsLoading(false);
            }
        }
    }

    const getScheduledWorkshops = async (skip = 0) => {
        if (!scheduledWorkshopsLoading) setScheduledWorkshopsLoading(true);

        const response = await fetchWorkshops({ filters: { date_gte: today.toISOString(), _start: skip } })
        if (response && response.length > 0) {
            const newList = [...scheduledWorkshops, ...response];
            setScheduledWorkshops(newList);

            // Recursively fetch until all scheduled workshops are fetched
            if (response.length === 100) {
                console.debug("There are still more scheduled workshops...", response.length);
                await getScheduledWorkshops(newList.length)
            } else {
                setScheduledWorkshopsLoading(false);
            }
        }
    }

    useEffect(() => {
        getScheduledWorkshops();
        getPastWorkshops();
    }, [])

    return (
        <Container>
            <Box sx={{ my: 5 }}>
                <Button onClick={() => setShowAddWorkshopDialog(true)} variant="contained" color="primary">
                    Add Live Event
                </Button>
                <Dialog open={showAddWorkshopDialog} onClose={() => setShowAddWorkshopDialog(false)} maxWidth="lg">
                    <Box sx={{ px: 5, pb: 2 }}>
                        <WorkshopForm workshops={scheduledWorkshops} setWorkshops={setScheduledWorkshops} setShowAddWorkshopDialog={setShowAddWorkshopDialog} getWorkshops={() => { }} />
                    </Box>
                </Dialog>
            </Box>
            {scheduledWorkshops && scheduledWorkshops.length > 0 && (
                <Box sx={{ py: 3 }}>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        Upcoming Live
                    </Typography>
                    <WorkshopTable workshops={scheduledWorkshops} setWorkshops={setScheduledWorkshops} loading={scheduledWorkshopsLoading} />
                </Box>
            )}
            {pastWorkshops && pastWorkshops.length > 0 && (
                <Box>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        Past Live
                    </Typography>
                    <WorkshopTable workshops={pastWorkshops} setWorkshops={setPastWorkshops} past={true} loading={pastWorkshopsLoading} />
                </Box>
            )}
        </Container>
    )
}

export default WorkshopView
import { PAYMENTS_URL } from '../constants'
const nodeURL = process.env.REACT_APP_STRAPI_URL

const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}
/**
 * 
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'} method
 * @param {object | string} body
 * @returns {RequestInit}
 */
const getOpts = (method, body) => {
	return {
		method,
		headers: headers(),
		body: typeof (body) === 'object' ? JSON.stringify(body) : body
	}
}

// *********** CREATE A SUBSCRIPTION *********** //
export const createSubscription = async (priceId, customerId) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({
			priceId: priceId,
			customerId: customerId,
		}),
	}

	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/create-subscription`, opts)

	const json = await response.json()

	return json
}

// *********** FETCH SUBSCRIPTION ITEMS FOR A GIVEN SUBSCRIPTION (BY SUBSCRIPTION ITEM ID) *********** //
export const fetchSubscriptionItems = async (subscriptionItemId) => {
	const opts = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}

	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/list?subscription_id=${subscriptionItemId}`, opts).then((data) => data.json())

	return response
}

// *********** FETCH SUBSCRIPTIONS (BY SUBSCRIPTION ID) *********** //
export const fetchSubscription = async (subscriptionId) => {
	const opts = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}
	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/${subscriptionId}`, opts).then((data) => data.json())

	return response
}

export const fetchUsersSubscription = async () => {
	const opts = {
		method: 'GET',
		headers: headers()
	}
	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/subscription`, opts).then((data) => data.json())

	return response
}

export const cancelSubscription = async (user, prorate, survey, cancel, refund) => {
	const { stripecustomerid, stripesubscriptionid, id } = user

	const opts = {
		method: 'DELETE',
		headers: headers(),
		body: JSON.stringify({
			subscriptionId: stripesubscriptionid,
			customerId: stripecustomerid,
			prorate: prorate,
			cancel: cancel,
			refund: refund
		}),
	}

	const cancellationOpts = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	}


	const existingCancellation = await fetch(`${nodeURL}/subscription-cancellations?users_permissions_user_eq=${id}&stripeSubscriptionId=${stripesubscriptionid}`, cancellationOpts).then((data) => data.json())

	if (existingCancellation.length > 0) {
		const surveyOpts = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
			body: JSON.stringify({
				reason: survey.cancel_reason,
				feedback: survey.feedback,
				restored: false
			}),
		}

		await fetch(`${nodeURL}/subscription-cancellations/${existingCancellation[0].id}?users_permissions_user_eq=${id}`, surveyOpts)
	} else {
		if (survey) {
			const surveyOpts = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				},
				body: JSON.stringify({
					stripeCustomerId: stripecustomerid,
					stripeSubscriptionId: stripesubscriptionid,
					users_permissions_user: id,
					reason: survey.cancel_reason,
					feedback: survey.feedback
				}),
			}
			await fetch(`${nodeURL}/subscription-cancellations`, surveyOpts)
		}
	}
	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/subscription`, opts).then((data) => data.json())

	return response
}

export const restoreSubscription = async (subId, userId) => {
	const cancellationOpts = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	}

	const existingCancellation = await fetch(`${nodeURL}/subscription-cancellations?users_permissions_user_eq=${userId}&stripeSubscriptionId=${subId}`, cancellationOpts).then((data) => data.json())

	if (existingCancellation.length > 0) {
		const surveyOpts = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
			body: JSON.stringify({
				restored: true
			}),
		}

		await fetch(`${nodeURL}/subscription-cancellations/${existingCancellation[0].id}?users_permissions_user_eq=${userId}`, surveyOpts)
	}

	const opts = {
		method: 'PUT',
		headers: headers(),
		body: JSON.stringify({ cancel_at_period_end: false }),
	}
	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/subscription/${subId}`, opts)

	return await response.json()
}

export const checkSubscriptionValid = async (subscriptionId) => {
	if (!subscriptionId) return { valid: false }

	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/subscription/${subscriptionId}/valid`, opts).then((data) => data.json())
	return response
}

export const getSetupIntentSecret = async () => {
	const opts = {
		method: 'GET',
		headers: headers()
	}

	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/create-setup-intent`, opts).then((data) => data.json())
	return response
}

export const completeCheckout = async (paymentMethodId, subscriptionPriceId, user, updateProfile, coupon = "") => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({
			default_payment_method: paymentMethodId,
			items: [{ price: subscriptionPriceId }],
			coupon: coupon
		})
	}

	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/complete-checkout`, opts).then((data) => data.json())

	// Ohh, this this isn't right... this needs to be done on the backend. I don't think this even actually updates strapi.
	const strapiBody = { stripesubscriptionid: `${response.id}` }

	updateProfile({ ...user, ...strapiBody });

	return response
}

export const checkIAPHubSubscription = async (purchaseId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/subscriptions/iaphub/${purchaseId}`, opts).then((data) => data.json())
	return response
}

/**
 * Updates the subscription for the logged in user
* @param {string} subscriptionId 
* @param {object} values 
 */
export const updateSubscriptionUser = async (subscriptionId, values) => {
	const response = await fetch(`${PAYMENTS_URL}/payments/subscriptions/subscription/${subscriptionId}`, getOpts("PUT", values))
	return await response.json()
}

export const cancelAndroidSubscription = async (iaphubpurchaseid) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ iaphubpurchaseid })
	}

	const response = await fetch(`${nodeURL}/iaphub/cancel-android`, opts)
	return await response.json()
}
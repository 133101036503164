import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { LoginJWT } from './authentication/login';
import { Dialog, DialogContent, Typography, Box } from '@material-ui/core';

const AuthGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, user, isLoading } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [open, setOpen] = useState(true);

  if (isLoading) {
    return <></>;
  }

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2, pb: 5 }}>
          <DialogContent>
            <Typography variant="h4" gutterBottom>Please Login to Continue</Typography>
            <LoginJWT />
          </DialogContent>
        </Box>
      </Dialog>
    );
  }

  if (location.pathname.includes('dashboard') && user.subtype !== 'publisher') {
    return <Navigate to='/' />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;

import { paymentsApi } from "./paymentsApi"

const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}
// *********** FETCH A USER (BY ID) *********** //
export const fetchUser = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/${id}`, opts).then((data) => data.json())

	return response
}

// *********** FETCH A USER (BY ID) *********** //
export const fetchUserFilters = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/filters/${id}`, opts).then((data) => data.json())

	return response
}

// *********** UPDATE A USER (BY ID) *********** //
export const updateUser = async (id, details) => {
	const opts = {
		method: 'PUT',
		headers: headers(),
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/users/${id}`, opts).then((data) => data.json())

	// Update stripe
	if (details.email && response.stripecustomerid) {
		const striperesp = await paymentsApi.updateCustomer({ id: response.stripecustomerid, email: details.email })
		if (striperesp.error) console.log("Error updating account email in stripe")
	}

	return response
}

// *********** DELETE A USER (BY ID) *********** //
export const deleteUser = async (id) => {
	const opts = {
		method: 'DELETE',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/${id}`, opts).then((data) => data.json())

	return response
}

/**
 * 
 * @param {boolean} refresh 
 * @returns 
 */
export const getCurrentUser = async (refresh = false) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	// We want to override the refresh parameter if the last refresh was more than an hour ago
	// This helps keep activity streak data up to date if a tab is left open overnight or something
	if (!refresh) {
		const raw = sessionStorage.getItem('lastRefresh');
		const lastRefresh = raw ? new Date(raw) : null;
		// If last refresh was more than an hour ago, refresh0
		if (lastRefresh && new Date() - lastRefresh > 1000 * 60 * 60) {
			refresh = true;
		}
	}

	if (refresh) {
		sessionStorage.setItem('lastRefresh', new Date().toISOString())
	}

	const response = await fetch(`${nodeURL}/users/me?refresh=${refresh}`, opts).then((data) => data.json())

	return response
}

export const sendPasswordResetEmail = async (email) => {
	const opts = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email: email }),
	}

	const response = await fetch(`${nodeURL}/auth/forgot-password`, opts).then((data) => data.json())

	return response
}

// details = {code, password, passwordConfirmation}
/**
 * @typedef {Object} PasswordResetDetails
 * @property {string} code
 * @property {string} password
 * @property {string} passwordConfirmation
 * 
 * @param {PasswordResetDetails} details 
 * @returns 
 */
export const submitPasswordReset = async (details) => {
	const opts = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/auth/reset-password`, opts).then((data) => data.json())

	return response
}

export const checkEmailExists = async (email) => {
	const opts = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	}

	const response = await fetch(`${nodeURL}/users/exists/${email}`, opts).then((data) => data.json())

	return response
}

export const sendInvoice = async (userId, invoiceUrl) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ userId, invoiceUrl }),
	}

	const response = await fetch(`${nodeURL}/users/send-invoice`, opts).then((data) => data.json())

	return response
}

export const activateTV = async (deviceId, deviceCode) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ deviceId, deviceCode }),
	}

	const response = await fetch(`${nodeURL}/tv-devices/activate`, opts).then((data) => data.json())

	return response
}

/**
 * @typedef {Object} FeatureFlags
 * @property {boolean} subscriptions
 * @property {boolean} workshops
 * @property {boolean} rulesBasedNoti
 * @property {boolean} oneTimeNoti
 * @property {boolean} myEther
 */

/**
 * @returns {Promise<FeatureFlags>}
 */
export const fetchFeatureFlags = async () => {
	const featureFlags = sessionStorage.getItem('featureFlags')
	try {
		if (featureFlags) return JSON.parse(featureFlags)

		const opts = {
			method: 'GET',
			headers: headers(),
		}

		const response = await fetch(`${nodeURL}/feature-flags`, opts);
		if (response.status !== 200) {
			throw new Error(`received non-200 response from server: ${response.statusText}`);
		}

		const data = await response.json();
		sessionStorage.setItem('featureFlags', JSON.stringify(data))
		return data;
	} catch (error) {
		console.error("error fetching feature flags: ", error);
		return {};
	}
}

/**
 * @typedef {Object} PointsLedgerEntry
 * @property {string} _id
 * @property {string} userId
 * @property {number} amount
 * @property {string} note
 */

/**
 * 
 * @param {string} userId
 * @returns {Promise<PointsLedgerEntry[]>}
 */
export const getUserLedgerById = async (userId, skip = 0, limit = 10) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/points-ledgers/user/${userId}?skip=${skip}&limit=${limit}`, opts).then((data) => data.json())

	return response
}

export const getUserLedgerByIdCount = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/points-ledgers/user/${userId}/count`, opts).then((data) => data.json())

	return response
}